import React from "react";
import {
  ArrowDown,
  ArrowLeft,
  ArrowRight,
  ArrowUp,
  BriefcaseBusiness,
  Calendar,
  CalendarRange,
  ChartLine,
  ChevronDownIcon,
  ChevronLeft,
  ChevronRightIcon,
  ChevronUp,
  CircleUserRound,
  CircleX,
  LayoutDashboard,
  FileInput,
  FileOutput,
  Files,
  GanttChart,
  Gavel,
  House,
  Info,
  Landmark,
  LucideProps,
  Mail,
  Menu,
  Monitor,
  Phone,
  Smartphone,
  TriangleAlert,
  X,
  CalendarClock,
  HelpCircle,
  Grid2X2,
  ChartColumn,
  Settings,
  FileStack,
  Search,
  ListOrdered,
  UsersRound,
  User,
  Download,
  NotebookText,
} from "lucide-react";

import {
  Icon as ChakraIcon,
  IconProps as ChakraIconProps,
} from "@chakra-ui/react";

// Mapping custom icon names to Lucide icons
// https://lucide.dev/icons/
export const iconMap = {
  alert: TriangleAlert,
  arrowRight: ArrowRight,
  arrowDown: ArrowDown,
  arrowLeft: ArrowLeft,
  arrowUp: ArrowUp,
  calendar: Calendar,
  calendarClock: CalendarClock,
  calendarRange: CalendarRange,
  chartColumn: ChartColumn,
  chartLine: ChartLine,
  chevronRight: ChevronRightIcon,
  chevronDown: ChevronDownIcon,
  chevronLeft: ChevronLeft,
  chevronUp: ChevronUp,
  close: X,
  closeCircle: CircleX,
  company: BriefcaseBusiness,
  dashboard: LayoutDashboard,
  download: Download,
  export: FileOutput,
  email: Mail,
  files: Files,
  fileStack: FileStack,
  gantt: GanttChart,
  grid2x2: Grid2X2,
  help: HelpCircle,
  home: House,
  info: Info,
  import: FileInput,
  landmark: Landmark,
  mail: Mail,
  mar: Gavel,
  menu: Menu,
  monitor: Monitor,
  phone: Phone,
  portfolio: BriefcaseBusiness,
  projectPlan: NotebookText,
  search: Search,
  smartPhone: Smartphone,
  settings: Settings,
  passwordList: ListOrdered,
  mobilePhone: Smartphone,
  user: User,
  userCircle: CircleUserRound,
  users: UsersRound,
} as const;

type IconName = keyof typeof iconMap;

export type IconProps = Omit<LucideProps, "ref"> & {
  name: IconName;
} & Omit<ChakraIconProps, "name">;

const Icon = (props: IconProps) => {
  const { name, size, strokeWidth } = props;
  const IconComponent = iconMap[name];
  return (
    <ChakraIcon
      {...props}
      boxSize={size ?? 6}
      strokeWidth={strokeWidth ?? "1.5px"}
      size={size}
      as={IconComponent}
    />
  );
};

export default Icon;
