import { Flex, Link as ChakraLink } from "@chakra-ui/react";
import Icon from "@eam/incentive-ui/components/Icon";
import { Fragment } from "react";

const DefaultHelpdeskData = () => {
  return (
    <Fragment>
      <Flex alignItems="center" mt="7" gap="4">
        <Icon name="email" size={5} />
        <ChakraLink
          fontWeight="500"
          href="mailto:helpdesk@allshares.com"
          fontSize="small"
        >
          helpdesk@allshares.com
        </ChakraLink>
      </Flex>
    </Fragment>
  );
};

export default DefaultHelpdeskData;
